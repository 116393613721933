/* General content styling */

/* body styles */

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--colour-brand);
  font-weight: normal;
  font-size: var(--font-size-normal);
  font-family: var(--font-body);
  line-height: 1.4;
  background-color: var(--colour-body);
  text-decoration-skip-ink: auto;
  text-rendering: optimizeLegibility;

  /* stylelint-disable */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  /* stylelint-enable */
}

p {
  margin: 15px 0;
}

.text-small {
  font-size: var(--font-size-small);
}

/* header styles */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.heading-super {
  margin: var(--gutter) 0 0;
  color: var(--colour-brand);
  font-weight: var(--font-weight-headings);
  font-family: var(--font-body);
  line-height: 1.2;
  transform: translateZ(0);
}

h1,
.h1 {
  font-size: 50px;
  text-transform: capitalize;
}

h2,
.h2 {
  font-size: 40px;
}

h3,
.h3 {
  font-size: 30px;
}

h4,
.h4 {
  font-size: 28px;
}

h5,
.h5 {
  font-size: 24px;
}

h6,
.h6 {
  font-size: 20px;
}

.heading-super {
  font-size: 60px;
}

/* horizontal rule */

hr {
  margin: var(--gutter) -200px var(--gutter) 0;
  border: 0;
  border-bottom: var(--colour-brand) 2px solid;

  @media (--medium) {
    margin: var(--gutter) 0;
  }
}

strong,
.strong {
  font-weight: var(--font-weight-headings);
  font-family: var(--font-body);
}

/* lists */

ul,
ol {
  padding-left: var(--gutter);

  li:not(:first-child) {
    margin-top: var(--gutter-small);

    @media (--medium) {
      margin-top: var(--gutter);
    }
  }
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: none;
  counter-reset: item;
}

ol > li {
  position: relative;
  margin-left: -var(--gutter);
  padding-left: var(--gutter);
  counter-increment: item;
}

ol > li::before {
  position: absolute;
  top: 0;
  left: 3px;
  display: inline-block;
  width: 1em;
  padding-right: var(--gutter);
  font-weight: normal !important;
  font-family: var(--font-headings);
  content: counter(item) '.';
}

/* quotes */

blockquote {
  position: relative;
  padding-left: var(--gutter);
  color: var(--colour-grey-copy);
  font-weight: normal !important;
  font-size: var(--font-size-large);
  font-family: var(--font-headings);
  line-height: 1.5;

  &::before {
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    width: 3px;
    background-color: var(--colour-grey-lighter);
    content: '';
  }

  cite {
    position: relative;
    display: block;
    margin: var(--gutter-small) 0 0;
    font-size: var(--font-size-normal);
    font-style: normal;

    &::before {
      content: '- ';
    }
  }
}

/* User text selection colours */

::selection {
  background: var(--colour-main-select);
}

/* eri Logos for the cs tool */

.Svg-logo-mobile {
  display: block;

  @media (--mediumlarge) {
    display: none;
  }
}

.Svg-logo-desktop {
  display: none;

  @media (--mediumlarge) {
    display: block;
  }
}
